<template>
  <fragment>
    <v-row v-if="!showRow">
      <v-col
        cols="12"
        md="11"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <p>{{ executive.name }} {{ executive.apellidos }}</p>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <p>
              <span v-if="executive.role_id">
                {{ rolsFree.filter(e => e.id === executive.role_id)[0].name }}
              </span>
            </p>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <p>
              <span v-if="executive.email">
                {{ executive.email }}
              </span>
            </p>
          </v-col>
          <v-col
            cols="12"
            md="2"
          >
            <p>
              <span v-if="executive.phones.length > 0">
                <span v-if="executive.phones[0].code">
                  {{ `+${executive.phones[0].code}` }}
                </span>
                <span v-if="executive.phones[0].phone">
                  {{ `${executive.phones[0].phone}` }}
                </span>
                <span v-if="executive.phones[0].ext">
                  {{ ` X ${executive.phones[0].ext}` }}
                </span>
                <!--<span
                  v-for="(ema, indEma) in executive.emails"
                  :key="indEma"
                > {{ ema.email }}<br /> </span>-->
              </span>
            </p>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              fab
              outlined
              v-bind="attrs"
              v-on="on"
              @click="setShowRow"
            >
              {{ showRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
            </v-icon>
          </template>
          <span>{{ showRow ? $t('lbl.hidden') : $t('lbl.show') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row v-if="showRow">
      <v-col
        cols="12"
        md="5"
      >
        <v-row>
          <v-col
            cols="12"
            md="10"
          >
            <v-select
              v-model="executive.role_id"
              :items="rolsFree"
              item-text="name"
              item-value="id"
              :label="$t('lbl.rol')"
              outlined
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="1"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mt-1 mx-2"
                  fab
                  dark
                  x-small
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteContact()"
                >
                  <v-icon small>
                    {{ icons.mdiTrashCan }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t('btn.delete') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="7"
      >
        <v-row>
          <v-col
            v-if="showRow"
            cols="12"
            md="10"
          >
            <v-select
              v-model="executive.user_id"
              :items="usersFree"
              item-text="nameComodin"
              item-value="id"
              :label="$t('lbl.name')"
              outlined
              dense
              hide-details
              @change="setUser"
            ></v-select>
            <v-row
              v-if="executive.user_id"
              class="mt-2 ml-2"
            >
              <v-col
                cols="12"
                md="10"
              >
                <b>Email:</b> {{ usersFree.filter(e => e.id === executive.user_id)[0].email }}
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            md="1"
          ></v-col>
          <v-col
            cols="12"
            md="1"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  fab
                  outlined
                  v-bind="attrs"
                  v-on="on"
                  @click="setShowRow"
                >
                  {{ showRow ? icons.mdiMenuDown : icons.mdiMenuRight }}
                </v-icon>
              </template>
              <span>{{ showRow ? $t('lbl.hidden') : $t('lbl.show') }}</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="mt-2 mb-2"></v-divider>
  </fragment>
</template>

<script>
import { mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    executive: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    model: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    executives: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    rols: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    users: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      isLoadingSuplementos: true,
      isDialogSuplemento: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      showRow: false,
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    rolsFree() {
      const arr = []
      this.rols.forEach(element => {
        if (element.slug === 'vendedor' || element.slug === 'cargador' || element.slug === 'gestor') {
          arr.push(element)
        }
      })

      return arr
    },
    usersFree() {
      const arr = []
      if (this.executive.role_id) {
        this.users.forEach(element => {
          if (element.role_id === this.executive.role_id) {
            arr.push({
              ...element,
              nameComodin: `${element.name} ${element.apellidos}`,
            })
          }
        })
      }

      return arr
    },
  },
  created() {
    if (this.pos === 0 && this.executives.length === 1) {
      this.showRow = true
    } else if (!this.executive.name) {
      this.showRow = true
    }
  },
  methods: {
    ...mapMutations(['deleteContactsAfiliados']),
    setShowRow() {
      this.$nextTick(() => {
        this.showRow = !this.showRow
      })
    },
    deleteContact() {
      this.model.executives.splice(this.pos, 1)
    },
    setUser() {
      if (this.executive.user_id) {
        const user = this.users.filter(e => e.id === this.executive.user_id)[0]
        this.executive.name = user.name
        this.executive.apellidos = user.apellidos
        this.executive.email = user.email
      } else {
        this.executive.name = null
        this.executive.apellidos = null
        this.executive.email = null
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
