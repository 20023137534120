var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[(!_vm.showRow)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"11"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('p',[_vm._v(_vm._s(_vm.executive.name)+" "+_vm._s(_vm.executive.apellidos))])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('p',[(_vm.executive.role_id)?_c('span',[_vm._v(" "+_vm._s(_vm.rolsFree.filter(function (e) { return e.id === _vm.executive.role_id; })[0].name)+" ")]):_vm._e()])]),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('p',[(_vm.executive.email)?_c('span',[_vm._v(" "+_vm._s(_vm.executive.email)+" ")]):_vm._e()])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('p',[(_vm.executive.phones.length > 0)?_c('span',[(_vm.executive.phones[0].code)?_c('span',[_vm._v(" "+_vm._s(("+" + (_vm.executive.phones[0].code)))+" ")]):_vm._e(),(_vm.executive.phones[0].phone)?_c('span',[_vm._v(" "+_vm._s(("" + (_vm.executive.phones[0].phone)))+" ")]):_vm._e(),(_vm.executive.phones[0].ext)?_c('span',[_vm._v(" "+_vm._s((" X " + (_vm.executive.phones[0].ext)))+" ")]):_vm._e()]):_vm._e()])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"fab":"","outlined":""},on:{"click":_vm.setShowRow}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.showRow ? _vm.icons.mdiMenuDown : _vm.icons.mdiMenuRight)+" ")])]}}],null,false,4127223795)},[_c('span',[_vm._v(_vm._s(_vm.showRow ? _vm.$t('lbl.hidden') : _vm.$t('lbl.show')))])])],1)],1):_vm._e(),(_vm.showRow)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-select',{attrs:{"items":_vm.rolsFree,"item-text":"name","item-value":"id","label":_vm.$t('lbl.rol'),"outlined":"","dense":"","hide-details":""},model:{value:(_vm.executive.role_id),callback:function ($$v) {_vm.$set(_vm.executive, "role_id", $$v)},expression:"executive.role_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-1 mx-2",attrs:{"fab":"","dark":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.deleteContact()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCan)+" ")])],1)]}}],null,false,3334907182)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"7"}},[_c('v-row',[(_vm.showRow)?_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-select',{attrs:{"items":_vm.usersFree,"item-text":"nameComodin","item-value":"id","label":_vm.$t('lbl.name'),"outlined":"","dense":"","hide-details":""},on:{"change":_vm.setUser},model:{value:(_vm.executive.user_id),callback:function ($$v) {_vm.$set(_vm.executive, "user_id", $$v)},expression:"executive.user_id"}}),(_vm.executive.user_id)?_c('v-row',{staticClass:"mt-2 ml-2"},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('b',[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.usersFree.filter(function (e) { return e.id === _vm.executive.user_id; })[0].email)+" ")])],1):_vm._e()],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"1"}}),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"fab":"","outlined":""},on:{"click":_vm.setShowRow}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.showRow ? _vm.icons.mdiMenuDown : _vm.icons.mdiMenuRight)+" ")])]}}],null,false,4127223795)},[_c('span',[_vm._v(_vm._s(_vm.showRow ? _vm.$t('lbl.hidden') : _vm.$t('lbl.show')))])])],1)],1)],1)],1):_vm._e(),_c('v-divider',{staticClass:"mt-2 mb-2"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }